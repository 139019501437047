<template>
	<router-view />
	<xl-cookies v-if="needCookie" :langs="$store.state.langs" :bgImg="$importImg('cookies.png')" :iconImg="$importImg('theTriangle.svg')" @openPrivacyPolicy="openPrivacyPolicy"></xl-cookies>
	<privacy-policy :type="policyData.type" :title="policyData.title" origin="bravetroops" ref="policyRef"></privacy-policy>
</template>
<script setup>
import { XLCookies as XlCookies, lowVersionOfTheTip, loadAliyunCaptchaNew } from '@shining3d/xl-login';
import privacyPolicy from '@shining3d/info-component/infoList/privacyPolicy.vue';
import { $getCountry, $getInfo, $privacyUpdate, $doLang, $lang, $getNodeList, $importImg } from '@/assets/common/common.js';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import throttle from 'lodash/throttle';
import { VueCookieNext } from 'vue-cookie-next';
import _get from 'lodash/get';
import { getNoParseCountryCode } from '@shining3d/common-module/utils/libphonenumber.js';
import http from '@/utils/http.js';
import { setCookie } from '@/utils/common.js';

const route = useRoute();

if ( window.self === window.top || window.location.pathname !== '/space/modelShare' ){
	getNoParseCountryCode( http );
	$getCountry();
}
const needCookie = ref( false );
watch( route, ( newVal ) => {
	needCookie.value = !( newVal?.meta?.needAuth === false );
});
lowVersionOfTheTip();
loadAliyunCaptchaNew();
const $store = useStore();
const policyData = ref({});
const policyRef = ref( null );

// 给3D编辑器存储edcd配置信息
localStorage.setItem( 'threeEditor', JSON.stringify( _get( window, 'app.i18n.@shining3d/app.threeEditor' )));
// global中的枚举值
localStorage.setItem( 'materialCategoryEnums', JSON.stringify( window.app.global.materialCategoryEnums ));
localStorage.setItem( 'hdrCategoryEnums', JSON.stringify( window.app.global.hdrCategoryEnums ));
localStorage.setItem( 'cdnUrl', JSON.stringify( window.app.global.cdnUrl ));
localStorage.setItem( 'cloud', window.app.global.cloud );
localStorage.setItem( 'feedbackCategoryEnums', JSON.stringify( window.app.global.feedbackCategoryEnums ));
localStorage.setItem( 'defaultHdr', JSON.stringify( window.app.global.defaultHdr ));

// 语言存储
$store.commit( 'setI18n', window.app.i18n || {});
// ETCD存储
$store.commit( 'setGlobal', window.app.global || {});
// 记录初次进入地址 适用ios>14单页spa，否则微信授权报错
sessionStorage.setItem( 'cloud_space_first_sign_url', window.location.href.split( '#' )[ 0 ]);

const openPrivacyPolicy = () => {
	$store.commit( 'setPolicy', 'privacyPolicy' );
};
const privacyPolicySwitch = computed(() => $store.state.privacyPolicySwitch );
const termsOfUseSwitch = computed(() => $store.state.termsOfUseSwitch );
// 隐私政策
watch( privacyPolicySwitch, ( val ) => {
	pChange( 'app-login.privacyPolicy', 'privacy' );
});

// 使用条款
watch( termsOfUseSwitch, ( val ) => {
	pChange( 'app-login.termsOfUse', 'termsOfUse' );
});

const pChange = throttle(
	( title, type ) => {
		policyData.value = { type, title };
		nextTick(() => {
			policyRef.value.getInfo();
			policyRef.value.dialogVisible = true;
		});
	},
	1000,
	{ leading: true, trailing: false }
);

const getQueryParam = ( param ) => new URLSearchParams( window.location.search ).get( param );

const lang = getQueryParam( 'lang' ); // 先判断是否有语言设置 因为需要reload
if ( lang ) {
	const plang = $doLang( lang );
	const curLang = $lang();
	$store.commit( 'setLangSwitch', false );
	if ( $store.state.langs.some(( l ) => l.value === plang ) && plang !== curLang ) { // 正确语言设置，并且和当前语言不一致
		VueCookieNext.setCookie( 'lang', plang, { expire: '3d', path: '/' });
		window.location.reload();
	}
}

const token = getQueryParam( 'token' );// 是否是从软件端携token跳转而来
if ( token ){
	// 设置cookie
	VueCookieNext.setCookie( 'token', token, { expire: '3d', path: '/' });
}

if ( VueCookieNext.getCookie( 'token' )) {
	// 获取用户信息
	$getInfo().then(() => {
		// 判断是否版本更新
		$privacyUpdate();
	});
}
if ( VueCookieNext.getCookie( 'token' )) {
	$getNodeList();
}

setCookie( 'editor-lang', VueCookieNext.getCookie( 'lang' ));
</script>
<style lang="scss">
@use '@/assets/common/common.scss' as *;
#app {
	width: 100%;
	height: 100%;
}
/* 清除默认的内外边距 /
/ 内减模型:盒子的边框与边距不会影响盒子的大小 */
body,
input,
img,
div,
ul,
li,
a,
span,
i,
p,
h1,
h2,
h3,
h4,
h5,
h6,
strong,
ins,
em,
del {
	margin: 0;
	padding: 0;
}
html,
body {
	height: 100%;
	font-family: $font-family;
	-webkit-font-smoothing: antialiased;
	color: $text-color;
}

/* 清除a标签的下划线和字体颜色 */
a {
	text-decoration: none;
}

/* 清除li标签前的小点点 */
li {
	list-style: none;
}

/* 清除input标签的边框线和外轮廓线 */
input {
	border: none;
	outline: none;
}

/* 清除img标签在地基浏览器中的自带边框 /
/ 控制文字与图片的中线对齐 */
img {
	border: none;
	vertical-align: middle;
}

/* 调整浏览器的默认字体大小为12px /
/ 设置 容器高度=字体大小 */
body {
	line-height: $line-height;
}
</style>
